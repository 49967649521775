Object.defineProperty(exports, '__esModule', { value: true });

exports.getPerson = async (username, docClient) => {
  const params = {
    TableName: process.env.STORAGE_PERSON_NAME,
    /* Item properties will depend on your application concerns */
    Key: {
      username: username,
    },
  }
  return await docClient.get(params).promise()
}
