import React, { useEffect, useState } from "react"
import Layout from "../components/layout-2"
import { Amplify, API, Auth, graphqlOperation, Hub } from "aws-amplify"
import awsconfic from "../aws-exports"
import { withAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import { settings } from "../shared"
import { getPersonBudgets } from "../utils/budget-queries"
import YnabAccount from "../components/ynab-account"
import LoadingAnimation from "../components/loading-animation"
import AccountSteps from "../components/account-steps"
import { DateTime, Interval } from "luxon"
import Attention from "../components/connections/attention"
import { navigate } from "gatsby"
import Expires from "../components/connections/expires"
import Footer from '../components/footer'

Amplify.configure(awsconfic)

interface Budget {
  name?: string
}

interface budgetC {
  name: Budget
}

const OwnConnections = ({ signOut, user, _serverData, location }) => {
  const [budget, setBudget] = useState({} as budgetC)
  const [loading, setLoading] = useState(true)
  const [expires, setExpires] = useState()
  const [userLoggedIn, setUserLoggedIn] = useState(!!user?.username)
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event } }) => {
      if (event === "signIn") {
        setUserLoggedIn(true)
      }
    })
  })
  useEffect(() => {
    // fetch all the notes
    void fetchUserSettings()
  }, [userLoggedIn])

  const fetchUserSettings = async () => {
    setLoading(true)
    const budgets = await getPersonBudgets()
    if (!budgets.activated) {
      const expires = DateTime.fromISO(budgets.createdAt).plus({ days: 7 })

      const now = DateTime.now()
      const diff = Interval.fromDateTimes(now, expires)
      let diffDays = diff.length("days")
      /*
      if (isNaN(diffDays)) {
        navigate("/subscribe")

      } else {
        setExpires(diffDays.toFixed())
      }
       */
    }
    setBudget(budgets)
    setLoading(false)
  }
  const authUrl = settings.authorizeUrl

  const content = budget?.name ? (
    <YnabAccount budget={budget} setBudget={setBudget} />
  ) : (
    <>
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Connect banks to <a className="text-lime-600 hover:text-lime-900"
                              href="https://www.youneedabudget.com/">YNAB</a>
        </h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          Start by authorizing budgetsyncer for YNAB. When connection has been
          authorized you can authorize bank account for each YNAB account. Each
          account can have one bank connection and you can control if you want
          to synchronize account automatically or manually. You can delete
          connected YNAB anytime you wan't and all data will be permanently
          deleted.
        </p>
      </div>
      <AccountSteps authUrl={authUrl} step={1} />
      <a
        className="inline-flex mt-10 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        href={authUrl}
      >
        Connect to YNAB
      </a>
    </>
  )
  const loadSplash = loading ? <LoadingAnimation /> : content
  return (
    <Layout path="/own-connections" signOut={signOut} userLoggedIn={userLoggedIn}>
      { false && expires && (
      <Expires expires={expires}></Expires>
      )}
      <header className="bg-white shadow-sm"></header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {loadSplash}
        </div>
      </main>

    </Layout>
  )
}

export default withAuthenticator(OwnConnections)
