Object.defineProperty(exports, "__esModule", { value: true })

const ynabClientID = "h_hg8nOVQhqf_YsfWo7DKSpIfLKalBdIe4hJDP8PxN8"
const ynabClientSecret = "UCIGJ3zycKDKu02mGkZZed7ymgvaN58JNCrwjn10Gsw"
const redirectUrl = "https://www.budgetsyncer.com/connect/"
const authorizeUrl = `https://app.youneedabudget.com/oauth/authorize?client_id=${ynabClientID}&redirect_uri=${redirectUrl}&response_type=code`
exports.settings = {
  testSetting: "testSetting",
  ynabClientID,
  ynabClientSecret,
  redirectUrl,
  authorizeUrl,
  testAuthorizeUrl: `https://app.youneedabudget.com/oauth/authorize?client_id=${ynabClientID}&redirect_uri=http://localhost:8000/connect&response_type=code`,
}
