import { API } from "aws-amplify"
import { getBanks, getBudgets, getPerson, initiateGocardless } from "../graphql/queries"

export const getPersonBudgets = async () => {
  const personData = await API.graphql({ query: getBudgets })
  if (personData.data) {
    return JSON.parse(personData.data.getBudgets)
  } else {
    return undefined
  }
}

export const getBanksfromCountry = async country => {
  const banks = await API.graphql({
    query: getBanks,
    variables: { msg: country },
  })
  return JSON.parse(banks.data.getBanks)
}

