import React, {useEffect, useImperativeHandle, useRef} from "react"
import '../css/loader-2.css'

export const renderCustomOption = (props, option, snapshot, className) => {
  const imgStyle = {
    borderRadius: "50%",
    verticalAlign: "middle",
    marginRight: 10,
    display: "inline-block",
  }

  return (
    <button {...props} className={className} type="button">
      <span>
        <img
          alt=""
          style={imgStyle}
          width="36"
          height="36"
          src={option.photo}
        />
        <span>{option.name}</span>
      </span>
    </button>
  )
}

export const renderCustomValue = (valueProps, snapshot, className) => {
  const {option} = snapshot
  const handleClick = () => {
    valueProps.ref?.current?.focus()
  }
  const style = {
    fontFamily:
      !snapshot.focus && option && "stack" in option ? option.stack : null,
  }
  const imgStyle = {
    borderRadius: "50%",
    verticalAlign: "middle",
    marginRight: 10,
    marginTop: '2px',
    marginBottom: '2px',
    display: "inline-block",
  }
  const img = snapshot.option?.photo ? (
    <img
      alt=""
      style={imgStyle}
      width="36"
      height="36"
      src={snapshot.option?.photo}
    />
  ) : (<div className="loader"></div>)
  const inputVal = snapshot.focus ? snapshot.search : snapshot.displayValue
  return (
    <div
      className="select-search__input input-flex"
      onClick={handleClick}
    >
      {img}
      <input
        className="picture-input"
        {...valueProps}
        style={style}
        value={inputVal}
      />
    </div>
  )
}
