/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { HeartIcon, XIcon } from "@heroicons/react/outline"
import { PencilIcon, PlusSmIcon } from "@heroicons/react/solid"

export default function Help({
                               open,
                               setOpen
                             }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-white p-8">

                    <div className="px-4 sm:px-6 lg:px-8 mt-20">
                      <h2 className="font-semibold text-gray-700">Connecting Bank to YNAB</h2>
                      <p className="mt-4 text-gray-700">Hey there! 🌟 Ready to make managing your budget even easier?
                        With this handy page, you can
                        seamlessly connect your bank accounts to your YNAB budget! Say goodbye to manual entry and
                        hello to
                        automatic syncing of your transactions. Let's get started on making your budgeting journey
                        smoother than
                        ever!</p>
                      <p className="mt-4 text-gray-700">Exciting news! 🚀 Once you've connected your accounts,
                        transactions will be fetched every hour and cleverly matched to your YNAB budget. The best
                        part? With the power of AI, YNAB learns your spending habits over time, suggesting
                        categories automatically. Just a quick review and approval from you is all that's needed!
                        Plus, if you decide to delete a transaction in YNAB, it won't pop up again from
                        BudgetSyncer. How's that for hassle-free budgeting?"</p>
                      <p className="mt-4 text-gray-700">Got questions or need a helping hand? Don't hesitate to
                        reach out! Just shoot us an email at
                        <a className="text-indigo-800 hover:text-indico-600" href="mailto: admin@budgetsyncer.com"> admin@budgetsyncer.com </a>
                        , and I will be more than
                        happy to assist you. Your smooth budgeting experience is my top priority, and I am here to
                        make sure everything runs seamlessly for you!</p>

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
