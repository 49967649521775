/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from "react"
import { Listbox, Menu, Transition } from "@headlessui/react"
import {
  CheckIcon,
  ChevronDownIcon,
  SelectorIcon,
} from "@heroicons/react/solid"
import { languages } from "../utils/languages"
import SelectSearch from "react-select-search";
import {fuzzySearch} from "./bank-selector";
import {renderCustomOption, renderCustomValue} from "./custom-option";

export default function CountrySelector({ setCountry, country }) {
  const countries = languages ? languages() : []

  const selectCountry = country => {
    setCountry(country.toLowerCase())
  }

  const options = countries?.map((language, index) => {
    const alpha2 = language["alpha-2"]
    const flag = require(`../images/countries/${alpha2}.svg`).default
    return {
      name: language.name,
      value: alpha2,
      photo: flag
    }
  })
  return (
      <SelectSearch
          options={options}
          value={country.toUpperCase()}
          renderOption={renderCustomOption}
          renderValue={renderCustomValue}
          filterOptions={fuzzySearch}
          search
          onChange={selectCountry}
          placeholder="Choose your bank"
      />
  )
}
