/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react"

export default function AccountSteps({ authUrl, step }) {
  let step1 = step === 1 ? "current" : "complete"
  let step2 = step === 2 ? "current" : "complete"
  let step3 = step === 3 ? "upcoming" : "upcoming"
  if( step === 1) {
    step1 = 'current'
    step2 = 'upcoming'
    step3 = 'upcoming'
  }
  if( step === 2) {
    step1 = 'complete'
    step2 = 'current'
    step3 = 'upcoming'
  }
  if( step === 3) {
    step1 = 'complete'
    step2 = 'complete'
    step3 = 'current'
  }
  const steps = [
    { id: "Step 1", name: "Create account", href: "#", status: step1 },
    { id: "Step 2", name: "Connect to YNAB", href: authUrl, status: step2 },
    {
      id: "Step 3",
      name: "Connect bank account to YNAB account",
      href: "#",
      status: step3,
    },
  ]

  return (
    <nav aria-label="Progress" className="mb-20">
      <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map(step => (
          <li key={step.name} className="md:flex-1">
            {step.status === "complete" ? (
              <a
                href={step.href}
                className="group pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
              >
                <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="pl-4 py-2 flex flex-col border-l-4 border-indigo-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                aria-current="step"
              >
                <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
              >
                <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
