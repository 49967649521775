import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { milliunitsToEuros } from '../utils/currency'
import { API } from 'aws-amplify'
import { modifyConnection } from '../graphql/mutations'

export default function AccountSettings({
                                          account,
                                          open,
                                          setOpen,
                                          budget,
                                          deleteConnection,
                                          setAccount,
                                        }) {
  const [syncEnabled, setSyncEnabled] = useState(account.syncEnabled)
  const updateBudget = async accountToBeUpdated => {
    const reply = await API.graphql({
      query: modifyConnection,
      variables: {
        details: JSON.stringify(accountToBeUpdated),
      },
    })
    setOpen(false)
  }

  useEffect(() => {
    if (account) {
      const accountToBeUpdated = {
        operation: 'MODIFY',
        account: {
          ...account,
          syncEnabled,
        },
      }
      updateBudget(accountToBeUpdated)
    }
  }, [syncEnabled])

  const connectedBank = account.selectedAccount ? (
    <div>
      <h3 className="font-medium text-gray-900">Bank Information</h3>
      <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
        <div className="flex justify-between py-3 text-sm font-medium">
          <dt className="text-gray-500">account</dt>
          <dd className="text-gray-900">{account.selectedAccount.product}</dd>
        </div>
        <div className="flex justify-between py-3 text-sm font-medium">
          <dt className="text-gray-500">currency</dt>
          <dd className="text-gray-900">{account.selectedAccount.currency}</dd>
        </div>
        <div className="flex justify-between py-3 text-sm font-medium">
          <dt className="text-gray-500">Iban</dt>
          <dd className="text-gray-900">{account.selectedAccount.iban}</dd>
        </div>
        <div className="flex justify-between py-3 text-sm font-medium">
          <dt className="text-gray-500">Bic</dt>
          <dd className="text-gray-900">{account.selectedAccount.bic}</dd>
        </div>
      </dl>
      <div className="flex mt-7">
        <fieldset>
          <legend className="text-sm font-medium text-gray-900">Privacy</legend>
          <div className="mt-2 space-y-5">
            <div className="relative flex items-start">
              <div className="absolute flex h-5 items-center">
                <input
                  id="syncing-enabled"
                  name="privacy"
                  aria-describedby="syncing-enabled-description"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={syncEnabled === true}
                  onChange={() => setSyncEnabled(true)}
                />
              </div>
              <div className="pl-7 text-sm">
                <label
                  htmlFor="syncing-enabled"
                  className="font-medium text-gray-900"
                >
                  Syncing enabled
                </label>
                <p id="syncing-enabled-description" className="text-gray-500">
                  Bank account is synced automatically to YNAB
                </p>
              </div>
            </div>
            <div>
              <div className="relative flex items-start">
                <div className="absolute flex h-5 items-center">
                  <input
                    id="syncing-disabled"
                    name="syncing-disabled"
                    aria-describedby="syncing-disabled-description"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={syncEnabled === false}
                    onChange={() => setSyncEnabled(false)}
                  />
                </div>
                <div className="pl-7 text-sm">
                  <label
                    htmlFor="syncing-disabled"
                    className="font-medium text-gray-900"
                  >
                    {' '}
                    Syncing disabled
                  </label>
                  <p
                    id="syncing-disabled-description"
                    className="text-gray-500"
                  >
                    Bank account will not be synced to YNAB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="flex mt-7">
        <button
          type="button"
          onClick={() => deleteConnection(account)}
          className="flex-1 rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Disconenct bank
        </button>
      </div>
    </div>
  ) : (
    <div>
      <h3 className="font-medium text-gray-900">Bank account not connected</h3>
      <div className="flex mt-6">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="flex-1 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Connect with bank
        </button>
      </div>
    </div>
  )

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-lime-300 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-700">
                            {' '}
                            Account settings{' '}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-sky-300 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-600">
                            Control sync preferences and disconnect bank account
                            if needed.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <h3 className="font-medium text-gray-900">
                                Ynab Information
                              </h3>
                              <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">account</dt>
                                  <dd className="text-gray-900">
                                    {account.name}
                                  </dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">balance</dt>
                                  <dd className="text-gray-900">
                                    {milliunitsToEuros(
                                      account.balance,
                                      budget.currency_format,
                                    )}
                                  </dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">
                                    cleared balance
                                  </dt>
                                  <dd className="text-gray-900">
                                    {milliunitsToEuros(
                                      account.cleared_balance,
                                      budget.currency_format,
                                    )}
                                  </dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">
                                    uncleared balance
                                  </dt>
                                  <dd className="text-gray-900">
                                    {milliunitsToEuros(
                                      account.uncleared_balance,
                                      budget.currency_format,
                                    )}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                            {connectedBank}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
