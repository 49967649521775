/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const add = /* GraphQL */ `mutation Add($number1: Int, $number2: Int) {
  add(number1: $number1, number2: $number2)
}
` as GeneratedMutation<APITypes.AddMutationVariables, APITypes.AddMutation>;
export const initiateBankAgreement = /* GraphQL */ `mutation InitiateBankAgreement($details: String) {
  initiateBankAgreement(details: $details)
}
` as GeneratedMutation<
  APITypes.InitiateBankAgreementMutationVariables,
  APITypes.InitiateBankAgreementMutation
>;
export const selectBankAccount = /* GraphQL */ `mutation SelectBankAccount($details: String) {
  selectBankAccount(details: $details)
}
` as GeneratedMutation<
  APITypes.SelectBankAccountMutationVariables,
  APITypes.SelectBankAccountMutation
>;
export const finishSelectBankAccount = /* GraphQL */ `mutation FinishSelectBankAccount($details: String) {
  finishSelectBankAccount(details: $details)
}
` as GeneratedMutation<
  APITypes.FinishSelectBankAccountMutationVariables,
  APITypes.FinishSelectBankAccountMutation
>;
export const deleteBudget = /* GraphQL */ `mutation DeleteBudget($details: String) {
  deleteBudget(details: $details)
}
` as GeneratedMutation<
  APITypes.DeleteBudgetMutationVariables,
  APITypes.DeleteBudgetMutation
>;
export const modifyConnection = /* GraphQL */ `mutation ModifyConnection($details: String) {
  modifyConnection(details: $details)
}
` as GeneratedMutation<
  APITypes.ModifyConnectionMutationVariables,
  APITypes.ModifyConnectionMutation
>;
export const createPerson = /* GraphQL */ `mutation CreatePerson(
  $input: CreatePersonInput!
  $condition: ModelPersonConditionInput
) {
  createPerson(input: $input, condition: $condition) {
    id
    bank
    ynabCode
    ynabAccessToken
    ynabAccessExpires
    ynabRefreshRoken
    ynabBudget
    budgetName
    ynabAccounts {
      id
      ynab_id
      bank_id
      bank_pic
      __typename
    }
    description
    referral
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePersonMutationVariables,
  APITypes.CreatePersonMutation
>;
export const updatePerson = /* GraphQL */ `mutation UpdatePerson(
  $input: UpdatePersonInput!
  $condition: ModelPersonConditionInput
) {
  updatePerson(input: $input, condition: $condition) {
    id
    bank
    ynabCode
    ynabAccessToken
    ynabAccessExpires
    ynabRefreshRoken
    ynabBudget
    budgetName
    ynabAccounts {
      id
      ynab_id
      bank_id
      bank_pic
      __typename
    }
    description
    referral
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePersonMutationVariables,
  APITypes.UpdatePersonMutation
>;
export const deletePerson = /* GraphQL */ `mutation DeletePerson(
  $input: DeletePersonInput!
  $condition: ModelPersonConditionInput
) {
  deletePerson(input: $input, condition: $condition) {
    id
    bank
    ynabCode
    ynabAccessToken
    ynabAccessExpires
    ynabRefreshRoken
    ynabBudget
    budgetName
    ynabAccounts {
      id
      ynab_id
      bank_id
      bank_pic
      __typename
    }
    description
    referral
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePersonMutationVariables,
  APITypes.DeletePersonMutation
>;
