import React from "react"
import "../css/simple-spinner.css"

const SimpleSpinner = () => {
  return (
    <div className="simple-spinner">
      <div className="spinenr-container">
        <div className="loading"></div>
      </div>

      <div className="spinner-text"> Redirecting</div>
    </div>
  )
}

export default SimpleSpinner
