import React, { useState } from "react"
import { milliunitsToEuros } from "../utils/currency"
import SelectBank from "./select-bank"
import { API } from "aws-amplify"
import {
  deleteBudget,
  initiateBankAgreement,
  modifyConnection
} from "../graphql/mutations"
import { navigate } from "gatsby"
import BudgetSettings from "./budget-settings"
import Alert from "./alert"
import AccountSettings from "./account-settings"
import AccountSteps from "./account-steps"
import { DateTime } from "luxon"
import Help from "./help"
import { getPersonBudgets } from '../utils/budget-queries'

const formatDateTime = (dateString: any) => {
  if (dateString) {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_FULL)
  } else {
    return ""
  }
}

export default function YnabAccount({ budget, setBudget }) {
  const [open, setOpen] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [accountSettingsOpen, setAccountSettingsOpen] = useState(false)
  const [account, setAccount] = useState({})
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const connectBank = account => {
    setAccount(account)
    setOpen(true)
  }
  const deleteConnection = async account => {
    const filteredBudget = {
      ...budget,
      accounts: budget.accounts.map(connection => {
        const selectedAccount =
          connection.id === account.id ? undefined : connection.selectedAccount
        return {
          ...connection,
          selectedAccount
        }
      })
    }
    const accountToBeUpdated = {
      operation: "DELETE_CONNECTION",
      account: {
        ...account
      }
    }
    const reply = await API.graphql({
      query: modifyConnection,
      variables: {
        details: JSON.stringify(accountToBeUpdated)
      }
    })
    const budgets = await getPersonBudgets()
    setBudget(budgets)
    setOpen(false)
    setAccountSettingsOpen(false)
  }

  const createAgreement = async details => {
    const agreement = await API.graphql({
      query: initiateBankAgreement,
      variables: {
        details: JSON.stringify(details)
      }
    })
    const reply = JSON.parse(agreement.data.initiateBankAgreement)
    const uri = reply.body.redirect
    await navigate(uri)
  }

  const deleteBudgetFn = async () => {
    const reply = await API.graphql({
      query: deleteBudget,
      variables: {
        details: JSON.stringify(budget.id)
      }
    })
    const parsed = JSON.parse(reply.data.deleteBudget)
    setOpen(false)
    setDeleteModalOpen(false)
    navigate("/own-connections")
  }

  const editAccount = account => {
    setAccount(account)
    setAccountSettingsOpen(true)
  }
  const conAccounts = budget.accounts.filter(account => account.selectedAccount)
  const accountStep = conAccounts?.length ? undefined : (
    <AccountSteps authUrl="#" step={2} />
  )
  let accountSettings = undefined

  accountSettings = account?.id ? (
    <AccountSettings
      account={account}
      setAccount={setAccount}
      open={accountSettingsOpen}
      setOpen={setAccountSettingsOpen}
      budget={budget}
      deleteConnection={deleteConnection}
    />
  ) : undefined
  return (
    <>
      <Help open={helpOpen}
            setOpen={setHelpOpen} />
      <Alert
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        deleteBudget={deleteBudgetFn}
        header="Delete budget?"
        message="Deleting budget will delete all data, connections and cannot be undone. This
        will not affect your YNAB budget or bank account."
      />
      <SelectBank
        open={open}
        setOpen={setOpen}
        account={account}
        createAgreement={createAgreement}
      />
      <BudgetSettings
        budget={budget}
        open={settingsOpen}
        setOpen={setSettingsOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
      {accountSettings}
      <div className="min-h-full">
        {accountStep}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                {budget.name}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {budget.last_modified_on}
              </p>
            </div>
            <div className="pr-10">
              <button
                className="text-indigo-600 hover:text-indigo-900"
                onClick={() => setHelpOpen(true)}
              >
                Help
              </button>
            </div>
            <div>
              <button
                className="text-indigo-600 hover:text-indigo-900"
                onClick={() => setSettingsOpen(true)}
              >
                Budget settings
              </button>
            </div>
          </div>
          <div
            className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Account
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Balance
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Bank
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Bank connection valid until
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Last synced
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {budget.accounts.map(account => (
                <tr key={account.name}>
                  <td
                    className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {account.name}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {milliunitsToEuros(
                      account.balance,
                      budget.currency_format
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {account.selectedAccount ? (
                      <>
                        <img
                          className="icon-style"
                          src={account.selectedAccount.logo}
                          alt={account.selectedAccount.bic}
                        />
                        {account.selectedAccount.iban}
                      </>
                    ) : (
                      <button
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={e => connectBank(account)}
                      >
                        Connect bank account
                      </button>
                    )}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {formatDateTime(account.validUntil)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {formatDateTime(account.lastSynced)}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button
                      onClick={() => editAccount(account)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit<span className="sr-only">, {account.name}</span>
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </>
  )
}
