export const languages = () => [
  {
    name: "Austria",
    "alpha-2": "AT",
    "country-code": "040",
  },
  { name: "Belgium", "alpha-2": "BE", "country-code": "056" },
  { name: "Bulgaria", "alpha-2": "BG", "country-code": "100" },
  {
    name: "Croatia",
    "alpha-2": "HR",
    "country-code": "191",
  },
  { name: "Cyprus", "alpha-2": "CY", "country-code": "196" },
  {
    name: "Czechia",
    "alpha-2": "CZ",
    "country-code": "203",
  },
  { name: "Denmark", "alpha-2": "DK", "country-code": "208" },
  {
    name: "Estonia",
    "alpha-2": "EE",
    "country-code": "233",
  },
  {
    name: "Finland",
    "alpha-2": "FI",
    "country-code": "246",
  },
  { name: "France", "alpha-2": "FR", "country-code": "250" },
  {
    name: "Germany",
    "alpha-2": "DE",
    "country-code": "276",
  },
  { name: "Greece", "alpha-2": "GR", "country-code": "300" },
  {
    name: "Hungary",
    "alpha-2": "HU",
    "country-code": "348",
  },
  { name: "Iceland", "alpha-2": "IS", "country-code": "352" },
  {
    name: "Ireland",
    "alpha-2": "IE",
    "country-code": "372",
  },
  { name: "Italy", "alpha-2": "IT", "country-code": "380" },
  { name: "Latvia", "alpha-2": "LV", "country-code": "428" },
  {
    name: "Liechtenstein",
    "alpha-2": "LI",
    "country-code": "438",
  },
  { name: "Lithuania", "alpha-2": "LT", "country-code": "440" },
  {
    name: "Luxembourg",
    "alpha-2": "LU",
    "country-code": "442",
  },
  { name: "Malta", "alpha-2": "MT", "country-code": "470" },
  {
    name: "Norway",
    "alpha-2": "NO",
    "country-code": "578",
  },
  { name: "Poland", "alpha-2": "PL", "country-code": "616" },
  {
    name: "Portugal",
    "alpha-2": "PT",
    "country-code": "620",
  },
  {
    name: "Romania",
    "alpha-2": "RO",
    "country-code": "642",
  },
  {
    name: "Slovakia",
    "alpha-2": "SK",
    "country-code": "703",
  },
  { name: "Slovenia", "alpha-2": "SI", "country-code": "705" },
  {
    name: "Spain",
    "alpha-2": "ES",
    "country-code": "724",
  },
  { name: "Sweden", "alpha-2": "SE", "country-code": "752" },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    "alpha-2": "GB",
    "country-code": "826",
  },
]
