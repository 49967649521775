/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from "react"

import Fuse from "fuse.js"

export function fuzzySearch(options) {
  const fuse = new Fuse(options, {
    keys: ["name", "groupName", "items.name"],
    threshold: 0.3,
  })

  return value => {
    if (!value.length) {
      return options
    }

    return fuse.search(value).map(({ item }) => item)
  }
}

import SelectSearch from "react-select-search"
import { renderCustomOption, renderCustomValue } from "./custom-option"

function getPlaceholderText(banksLoading: any, country: any) {
  if (!country) {
    return "First select country"
  } else {
    return banksLoading ? "Loading banks.." : "Choose your bank"
  }
}

export default function BankSelector({
  country,
  banks,
  bank,
  setBank,
  banksLoading,
}) {
  const selectableBanks = banks.map(bank => {
    return {
      id: bank.id,
      name: bank.name,
      avatar: bank.logo,
    }
  })

  const options = banks.map(bank => {
    return {
      name: bank.name,
      value: bank.id,
      photo: bank.logo,
    }
  })
  const onChange = value => {
    setBank(value)
  }
  const placeholder = getPlaceholderText(banksLoading, country)
  return (
    <SelectSearch
      options={options}
      value={bank}
      renderOption={renderCustomOption}
      filterOptions={fuzzySearch}
      renderValue={renderCustomValue}
      search
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}
